import { FC, Fragment, useState } from 'react';
import {
    ConsumerData,
    EditFormProps,
    Integrations as IntegrationsData,
    IntegrationValue,
} from '../data';
import {
    Box,
    Divider,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    SxProps,
    TextField,
    Theme,
    Tooltip,
    Typography,
} from '@mui/material';
import { ParamValueType } from '../__generated__/ConsumerEditQuery.graphql';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import ReadonlyInfoItem from './ReadonlyInfoItem';

const styles = {
    container: {
        display: 'flex',
    } as SxProps<Theme>,
    valuesContainer: {
        flexGrow: 1,
    } as SxProps<Theme>,
    selectedContainer: (readonly: boolean) => ({
        display: 'flex',
        flexDirection: 'column',
        gap: readonly ? 'unset' : '20px',
        minWidth: '450px',
        maxWidth: '70%',
    }) as SxProps<Theme>,
    divider: {
        margin: '0 16px',
    } as SxProps<Theme>,
    typeTitle: {
        position: 'absolute',
        fontSize: '10px',
        top: '-22px',
        right: 0,
    } as SxProps<Theme>,
    description: {
        position: 'relative',
        bottom: '5px',
    } as SxProps<Theme>,
    groupTitle: (theme => ({
        color: theme.palette.text.disabled,
    })) as SxProps<Theme>,
    integrationsGroup: {
        padding: '4px 0 12px 0',
    } as SxProps<Theme>,
    integrationItem: (selected: boolean) => (theme => ({
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '8px',
        padding: '6px 4px 6px 16px',
        cursor: 'pointer',
        color: theme.palette.primary.main,
        background: selected ? theme.palette.background.default : 'unset',
        borderRadius: '8px',
    })) as SxProps<Theme>,
};

// group title: [integration types]
const typeGroupTitles = {
    'IBV Providers': ['IBV'],
    'Loan Management Systems': ['LMS'],
    'Communication':
        ['SMS', 'Support', 'Communication', 'Trustpilot Review Platform'],
    'Lead Markets': ['Loan Leads Stock Market'],
};

// integration type: group title
const typesDict = Object.keys(typeGroupTitles).reduce(
    (prev, title) => ({
        ...prev,
        ...typeGroupTitles[title as keyof(typeof typeGroupTitles)].reduce(
            (prev, type) => ({ ...prev, [type]: title }),
            {} as {[id: string]: string},
        ),
    }),
    {} as {[id: string]: string},
);

const Integrations: FC<Pick<
    EditFormProps,
    'initial'
    | 'data'
    | 'setData'
    | 'integrations'
    | 'readonly'
>> = ({
    initial,
    data,
    setData,
    integrations,
    readonly,
}) => {
    // integration id: integration
    const integrationsDict = integrations.reduce((prev, integration) => {
        prev[integration.id] = integration;
        return prev;
    }, {} as {[id: string]: IntegrationsData[0]});

    // integration id: integration value
    const integrationValuesDict = (data.integrations || [])
        .reduce((prev, value) => {
            prev[value.id] = value;
            return prev;
        }, {} as {[id: string]: IntegrationValue});

    // integration id: initial integration value
    const initialValuesDict = (initial.integrations || [])
        .reduce((prev, value) => {
            prev[value.id] = value;
            return prev;
        }, {} as {[id: string]: IntegrationValue});

    const getGroupTitle = (id: string) => {
        const type = integrationsDict[id].type;
        return typesDict[type] || type;
    };

    // group title: [integration ids]
    const titles = Object.keys(integrationsDict).reduce((prev, id) => {
        const title = getGroupTitle(id);
        const ids = prev[title];
        prev[title] = ids ? [...ids, id] : [id];
        return prev;
    }, {} as {[title: string]: string[]});

    const [selectedId, setSelectedId] =
        useState<string | undefined>(integrations[0].id);

    const checkInitialAndSetData = (data: ConsumerData) => {
        const isIntegrationsEqual = (
            value: IntegrationValue,
            initial: IntegrationValue | undefined,
        ) => {
            const checkValues = (
                a: readonly { id: string; value: string; }[],
                b: readonly { id: string; value: string; }[],
            ) => !a.length || a.every(va => {
                const valuea = va.value;
                const valueb = b.find(vb => vb.id === va.id)?.value;
                return (!valuea && !valueb) || valuea === valueb;
            });

            return checkValues(value.headers || [], initial?.headers || []) &&
                checkValues(value.params || [], initial?.params || []) &&
                value.enabled === !!initial?.enabled;
        };

        setData(
            data.integrations!.every(integrationValue => isIntegrationsEqual(
                integrationValue,
                initialValuesDict[integrationValue.id],
            ))
                ? { ...data, integrations: initial.integrations }
                : data,
        );
    };

    const updateIntegrationValue = (
        id: string,
        update: (value: IntegrationValue) => IntegrationValue,
    ) => {
        const integration = integrationsDict[id];
        const value = integrationValuesDict[id];

        checkInitialAndSetData({
            ...data,
            integrations: [
                ...(data.integrations || []).filter(i => i.id !== id),
                update({
                    id,
                    enabled: !!value?.enabled,
                    headers: value?.headers || integration.headers?.map(h => ({
                        id: h.id,
                        value: '',
                    })),
                    params: value?.params || integration.params?.map(p => ({
                        id: p.id,
                        value: ''
                    })),
                }),
            ]
        });
    };

    return <Box sx={ styles.container }>
        <Box sx={ styles.valuesContainer }>
            { selectedId && <Box sx={ styles.selectedContainer(readonly) }>
                <Typography variant='h3'>
                    { integrationsDict[selectedId].name }
                </Typography>
                { selectedId &&
                  !!integrationsDict[selectedId].headers?.length && <>
                    <Typography
                        variant='h4'
                        padding={ readonly ? '16px 0' : undefined }
                    >
                        Headers
                    </Typography>
                    { [...integrationsDict[selectedId].headers!]
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((header, i) => <InputComponent
                            key={ header.id }
                            readonly={ readonly }
                            first={ i === 0 }
                            name={ header.name }
                            value={
                                integrationValuesDict[selectedId]?.headers
                                    ?.find(h => h.id === header.id)?.value || ''
                            }
                            onChange={ value => updateIntegrationValue(
                                selectedId,
                                integration => ({
                                    ...integration,
                                    headers: [
                                        ...integration.headers
                                            ?.filter(h => h.id !== header.id)
                                            || [],
                                        { id: header.id, value },
                                    ],
                                }),
                            ) }
                        />)
                    }
                </> }
                { selectedId &&
                  !!integrationsDict[selectedId].params?.length && <>
                    <Typography
                        color={ 'text.primary' }
                        variant='h4'
                        padding={ readonly ? '16px 0' : undefined }
                    >
                        Params
                    </Typography>
                    { [...integrationsDict[selectedId].params!]
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((param, i) => <InputComponent
                            key={ param.id }
                            readonly={ readonly }
                            first={ i === 0 }
                            name={ param.name }
                            type={ param.type }
                            description={ param.description || undefined }
                            value={
                                integrationValuesDict[selectedId]?.params
                                    ?.find(p => p.id === param.id)?.value || ''
                            }
                            onChange={ value => updateIntegrationValue(
                                selectedId,
                                integration => ({
                                    ...integration,
                                    params: [
                                        ...integration.params
                                            ?.filter(p => p.id !== param.id)
                                            || [],
                                        { id: param.id, value },
                                    ],
                                }),
                            ) }
                        />)
                    }
                </> }
            </Box> }
        </Box>
        <Divider orientation='vertical' flexItem sx={ styles.divider }/>
        <Box minWidth='240px'>
            { Object
                .keys(titles)
                .sort((a, b) => a.localeCompare(b))
                .map((title, index) => <Fragment
                key={ index }
            >
                <Typography sx={ styles.groupTitle }>
                    { title }
                </Typography>
                <Divider />
                <Box sx={ styles.integrationsGroup }>
                    { titles[title]
                        .sort((a, b) =>
                            integrationsDict[a].name
                                .localeCompare(integrationsDict[b].name)
                        )
                        .map(id => <Box
                            key={ id }
                            sx={ styles.integrationItem(selectedId === id) }
                            onClick={ () => setSelectedId(id) }
                        >
                            { integrationsDict[id].name }
                            <Switch
                                disabled={ readonly }
                                checked={ !!integrationValuesDict[id]?.enabled }
                                onChange={ (_, checked) =>
                                    updateIntegrationValue(
                                        id,
                                        value => ({
                                            ...value,
                                            enabled: checked,
                                        }),
                                    )
                                }
                            />
                        </Box>)
                    }
                </Box>
            </Fragment>) }
        </Box>
    </Box>
};

const InputComponent: FC<{
    name: string;
    value: string;
    onChange: (value: string) => void;
    type?: ParamValueType;
    description?: string;
    readonly: boolean;
    first: boolean;
}> = ({ name, value, onChange, type, description, readonly, first }) => {
    if (readonly) {
        return <ReadonlyInfoItem
            showDivider={ !first }
            title={ name }
            value={ value }
        />;
    }

    const endAdornment = <Box>
        <Typography sx={ styles.typeTitle }>
            { type ?? 'String' }
        </Typography>
        { description && <Box sx={ styles.description }>
            <Tooltip title={ description }>
                <IconButton size='small'>
                    <InfoIcon fontSize='small'/>
                </IconButton>
            </Tooltip>
        </Box> }
    </Box>;

    if (type === 'Bool'){
        return <FormControl>
            <InputLabel variant='standard'>
                { name }
            </InputLabel>
            <Select
                variant='standard'
                value={ value }
                onChange={ e => onChange(e.target.value) }
                endAdornment={ endAdornment }
            >
                <MenuItem value=''>unselect</MenuItem>
                <MenuItem value={ 'true' }>true</MenuItem>
                <MenuItem value={ 'false' }>false</MenuItem>
            </Select>
        </FormControl>;
    }

    return <TextField
        variant='standard'
        label={ name }
        type={ type === 'Int' || type === 'Float' ? 'number' : 'text' }
        value={ value }
        onChange={ e => {
            const value = type === 'Int'
                ? String(parseInt(e.target.value))
                : type === 'Float'
                    ? String(parseFloat(e.target.value as string))
                    : e.target.value;
            onChange(value);
        } }
        InputProps={{ endAdornment }}
    />;
}

export default Integrations;
